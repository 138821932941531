/*
 * @Description: Http请求模块
 * @Author: Mars
 * @Date: 2023-11-30 17:01:41
 * @LastEditTime: 2025/02/06 10:45:50
 */
import { createHttp } from '@alpha-ess/utils';
import request from '@/utils/request';
import requestChengdu from '@/utils/requestChengdu';

const http = createHttp(request);
export const chengduHttp = createHttp(requestChengdu);
export default http;
