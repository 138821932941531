/*
 * @Description: 菜单状态
 * @Author: Mars
 * @Date: 2023-11-30 15:34:40
 * @LastEditTime: 2024-06-12 13:11:19
 */
import { atom, selector } from 'recoil';
import { IMenuItem, IMicroItem } from '@/services/user/types';

interface IMenuState {
  menu?: IMenuItem[];
  microApps: IMicroItem[];
  defaultPage?: string;
}

export const defaultMenuState: IMenuState = {
  microApps: [],
};
/** 菜单状态 */
const menuState = atom<IMenuState>({ key: 'menuState', default: defaultMenuState });

/** 一维化菜单 */
export const flattenMenuState = selector<IMenuItem[]>({
  key: 'flattenMenuState',
  get: ({ get }) => {
    const menuData = get(menuState);
    if (menuData && menuData.menu) return getFlattenMenu(menuData.menu);
    return [];
  },
});

/** drawer菜单状态 */
export const drawerMenuState = atom({ key: 'drawerMenuState', default: false });

/**
 * @Author: Mars
 * @description: 获取一维化菜单
 * @param {IMenuItem} menu
 * @return {*}
 */
const getFlattenMenu = (menu: IMenuItem[]): IMenuItem[] => {
  const flattenMenu = [];
  for (const item of menu) {
    flattenMenu.push(item);
    if (item.children && item.children.length > 0) {
      flattenMenu.push(...getFlattenMenu(item.children));
    }
  }
  return flattenMenu;
};

export default menuState;
