/*
 * @Description: 国际化配置文件
 * @Author: Mars
 * @Date: 2023-12-01 09:55:17
 * @LastEditTime: 2024/09/14 17:33:23
 */
import { i18nRequest } from '@alpha-ess/utils';
import i18n from 'i18next';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import config, { isDev } from '@/config';
import { getLanguage } from '@/utils/language';

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init<HttpBackendOptions>({
    backend: { loadPath: config.localePath, request: i18nRequest },
    lng: getLanguage(),
    fallbackLng: false,
    ns: ['common'],
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    debug: isDev,
  });

export default i18n;
